export default class CustomRenderer {
    render({count, position}) {
        // change color if this cluster has more markers than the mean cluster
        let bodyStyles = window.getComputedStyle(document.body);
        const colorSecondary = bodyStyles.getPropertyValue('--bs-secondary');
        const colorSecondaryText = bodyStyles.getPropertyValue('--secondary-text-color');
        const colorLight = bodyStyles.getPropertyValue('--bs-light');
        const colorPrimary = bodyStyles.getPropertyValue('--bs-primary');
        // create svg url with fill color
        const svg = window.btoa(`
  <svg fill="${colorSecondary}" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 240 240">
    <circle cx="120" cy="120" opacity="1" r="70" />
    <circle cx="120" cy="120" opacity=".3" r="90" />
    <circle cx="120" cy="120" opacity=".2" r="110" />
  </svg>`);
        // create marker using svg icon
        return new google.maps.Marker({
            position,
            icon: {
                url: `data:image/svg+xml;base64,${svg}`,
                scaledSize: new google.maps.Size(45, 45),
            },
            label: {
                text: String(count),
                color: colorSecondaryText, // || colorPrimary,
                fontSize: "12px",
            },
            // adjust zIndex to be above other markers
            zIndex: Number(google.maps.Marker.MAX_ZINDEX) + count,
        });
    }
}
