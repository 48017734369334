// bootstrap is always needed
import '../node_modules/bootstrap/scss/bootstrap.scss';
import '../node_modules/bootstrap-icons/font/bootstrap-icons.css'

// load jquery and make it globaly available
// const $ = require('jquery');
// global.$ = global.jQuery = $;
// require('bootstrap');
// import 'bootstrap';
window.bootstrap = require("bootstrap");

require('bootstrap-select');
import '../node_modules/bootstrap-select/sass/bootstrap-select.scss'

// import '../node_modules/bootstrap-icons/font/bootstrap-icons.css';
// import '../node_modules/bootstrap/dist/js/bootstrap.bundle';
// import '../node_modules/jquery/dist/jquery';

import './styles/app.scss';
import './styles/slimselect.scss';
import './js/init-gmap';
import './js/init-forms';
import './js/init-viewheight';
import './js/init-admin';