import anyAscii from 'any-ascii';

export default class HvDataService {

    constructor(data) {
        let self = this;
        self.jsonData = data;
        self.filter = document.getElementById("filter");

        let bodyStyles = window.getComputedStyle(document.body);
        self.enableKantonLayer = bodyStyles.getPropertyValue('--map-enable-kanton').trim() === "true";
    }

    prepareFilter(callback, pushGeosearch = true) {
        // console.log("dataservice.prepareFilter");
        let self = this;
        // self.loading(true);
        // self.datatable.loading(true);

        // console.time('prepareFilter');
        setTimeout(function () {
            let distanceSelected = self.filter.querySelectorAll("input[name=distance]:checked").length > 0;
            let address = (self.filter.querySelector("[id=adresse]").value.length > 0 ? self.filter.querySelector("[id=adresse]").value + ", " : '')
                + self.filter.querySelector("[id=plz]").value + " " + self.filter.querySelector("[id=ort]").value;
            let addressEmpty = address.trim().length === 0;

            if (!addressEmpty && distanceSelected) {
                // console.log(self.lastAddress !== address);
                let showDistance = self.filter.querySelector("[id=plz]").value.length > 0 || self.filter.querySelector("[id=ort]").value.length > 0;

                if (self.lastAddress !== address) {
                    // console.log("geolocate: " + address + ", Schweiz");
                    let geocoder = new google.maps.Geocoder();
                    geocoder.geocode({'address': address + ", Schweiz"}, function (results, status) {
                        if (status === 'OK') {
                            // console.log("location found");
                            self.lastLocation = results[0].geometry.location;

                            let lat = results[0].geometry.location.lat();
                            let lng = results[0].geometry.location.lng();
                            if (pushGeosearch) {
                                dataLayer.push({
                                    'event': 'mapGeosearch',
                                    'click_lat': lat,
                                    'click_long': lng
                                });
                            }
                            self.lastAddress = address;
                            if (showDistance) {
                                self.calulcateDistances(lat, lng);
                            }
                            self._applyFilter(callback);
                        } else {
                            console.warn('Geocode was not successful for the following reason: ' + status);
                        }
                    });
                } else {
                    if (showDistance) {
                        self.calulcateDistances(self.lastLocation.lat(), self.lastLocation.lng());
                    }
                    self._applyFilter(callback);
                }

                if (showDistance) {
                    document.querySelector('[data-sort-list="distance"]').classList.remove("d-none");
                } else {
                    self.jsonData.forEach((item) => {
                        item["distance"] = 0;
                    });
                    document.querySelector('[data-sort-list="distance"]').classList.add("d-none");
                }
            } else {
                //unset distances
                self.jsonData.forEach((item) => {
                    item["distance"] = 0;
                });
                self._applyFilter(callback);
                document.querySelector('[data-sort-list="distance"]').classList.add("d-none");
            }

            // console.timeEnd('prepareFilter');
        }, 0);
    }

    isFilterSet() {
        let self = this;
        let filterSet = false;
        let distanceSelected = self.filter.querySelectorAll("input[name=distance]:checked").length > 0;

        self.filter.querySelectorAll("select,input").forEach((input) => {
            let value = input.value;
            if (distanceSelected && input.hasAttribute("data-addressfield")) {
                return;
            }
            if (input.getAttribute("type") === "radio") {
                if (input.checked) {
                    filterSet = true;
                }
            } else if (value.length > 0) {
                filterSet = true;
            }
        });

        return filterSet;
    }

    _applyFilter(callback) {
        // console.log("dataservice._applyFilter");
        let self = this;

        // console.time('applyFilter');
        // let markers = [];
        let filterValues = [];
        let distanceSelected = self.filter.querySelectorAll("input[name=distance]:checked").length > 0;

        self.filter.querySelectorAll("select,input").forEach((input) => {
            let value = input.value;

            if (distanceSelected && input.hasAttribute("data-addressfield")) {
                //ignore fields for direct search if distance is selected
                // console.log("ignore " + input.getAttribute("id"));
                return;
            }
            if (input.getAttribute("type") === "radio") {
                if (input.checked) {
                    filterValues.push({
                        id: input.getAttribute("name"),
                        type: input.tagName,
                        value: value
                    });
                }
            } else if (input.tagName === "SELECT") {
                if (value.length > 0) {
                    filterValues.push({
                        id: input.getAttribute("id"),
                        type: input.tagName,
                        value: value
                    });
                }
            } else {
                if (value.length > 0) {
                    if (value === "-") value = "";
                    value.split(" ").forEach((v) => {
                        filterValues.push({
                            id: input.getAttribute("id"),
                            type: input.tagName,
                            value: v
                        });
                    });
                }
            }
        });
        // console.log(filterValues);

        // if (self.enableKantonLayer) {
        //     const colorPrimary = this.bodyStyles.getPropertyValue('--bs-primary').trim();
        //     self.dataLayoutKantone.setStyle({
        //         fillColor: '#E5E3DF',
        //         fillOpacity: filterSet ? 0 : 1,
        //         strokeWeight: 0.5,
        //         strokeColor: colorPrimary,
        //     });
        //     for (const [key, kantonLabelMarker] of Object.entries(self.kantonLabels)) {
        //         // console.log(key);
        //         kantonLabelMarker.setMap(filterSet ? null : self.map);
        //     }
        // }
        if (self.isFilterSet()) {
            let collapse = window.bootstrap.Collapse.getInstance(document.getElementById("filter-container"));
            if (collapse) collapse.hide();
        }

        self.jsonData.forEach((item) => {
            item["display"] = self.enableKantonLayer ? self.isFilterSet() : true;

            filterValues.forEach((filterData) => {
                if (item[filterData.id] !== undefined) {
                    let value = item[filterData.id].toString();
                    if (filterData.id === "distance") {
                        if (parseFloat(value) > parseFloat(filterData.value)) {
                            item["display"] = false;
                        }
                    } else if (filterData.type === "INPUT") {
                        if (!anyAscii(value.toLowerCase()).includes(anyAscii(filterData.value.toLowerCase()))) {
                            item["display"] = false;
                        }
                    } else {
                        // console.log(filterData.type, filterData.value);
                        if (filterData.type === "SELECT" && filterData.value === "-") {
                            // console.log("ignore -");
                        } else {
                            if (value.split(', ').every(v => v !== filterData.value)) {
                                // }
                                // if (!filterData.value.split("|").includes(value)) {
                                item["display"] = false;
                            }
                        }
                    }
                } else {
                    console.warn("unknown attribute for filter: " + filterData.id + ":" + (typeof item[filterData.id]));
                    // console.log(item);
                }
            });
        });

        let markersVisible = 0;
        self.jsonData.forEach((item) => {
            if (item["display"]) markersVisible++;
        });
        // console.log("markers visible: " + markersVisible);

        if (markersVisible === 0) {
            let distanceSelected = self.filter.querySelectorAll("input[name=distance]:checked").length > 0;
            let locationSearch = self.filter.querySelector("input[id=adresse]").value.length > 0
                || self.filter.querySelector("input[id=plz]").value.length > 0
                || self.filter.querySelector("input[id=ort]").value.length > 0;

            //check if radius is set, if not set to 5km and rerun again.
            if (!distanceSelected && locationSearch) {
                // console.log("autoset distance");
                let radius5 = document.getElementById("distance-5");
                radius5.checked = true;
                radius5.classList.add("active");
                self.prepareFilter(callback);
                return;
            }
        }

        // console.timeEnd('applyFilter');
        callback();
    }

    calulcateDistances(latitude, longitude) {
        let self = this;
        // console.time("calulcateDistances");
        let R = 6371.0710; // Radius of the Earth in km

        // console.log(latitude, longitude);

        self.jsonData.forEach((item) => {
            let rlat1 = latitude * (Math.PI / 180); // Convert degrees to radians
            let rlat2 = item.latitude * (Math.PI / 180); // Convert degrees to radians
            let difflat = rlat2 - rlat1; // Radian difference (latitudes)
            let difflon = (item.longitude - longitude) * (Math.PI / 180); // Radian difference (longitudes)

            item["distance"] = 2 * R * Math.asin(Math.sqrt(Math.sin(difflat / 2) * Math.sin(difflat / 2) + Math.cos(rlat1) * Math.cos(rlat2) * Math.sin(difflon / 2) * Math.sin(difflon / 2)));
        });
        // console.timeEnd("calulcateDistances");
    }


}
