import SlimSelect from 'slim-select';

let selectFields = {};

function setSelected(id, value) {
    selectFields[id].set(value);
}

window.setSelected = setSelected;

document.addEventListener("DOMContentLoaded", () => {

    document.querySelectorAll("#filter-container select").forEach((element) => {
        selectFields[element.getAttribute("id")] = new SlimSelect({
            select: element,
            placeholder: true,
            showSearch: false
        });
    });

    document.querySelectorAll(".radio-group").forEach(toggleContainer => {
        let toggleElements = toggleContainer.querySelectorAll("input[type=radio]");

        toggleElements.forEach((toggleElement) => {
            toggleElement.addEventListener("click", () => {
                if (toggleElement.checked === true && toggleElement.classList.contains("active")) {
                    toggleElement.checked = false;
                    toggleElement.classList.remove("active")

                    // autosubmit:
                    // toggleElement.closest("#filter").dispatchEvent(new SubmitEvent("submit"));

                } else {
                    toggleElements.forEach((item) => {
                        item.classList.remove("active")
                    });
                    toggleElement.classList.add("active")
                }
            });
        });
    });

    let scrolltofixed = document.querySelector(".scrolltofixed");
    if (scrolltofixed) {
        var scrolltofixedSetter = function () {
            let header = document.querySelector("header");
            // console.log(window.innerHeight, scrolltofixed.clientHeight, header.clientHeight);
            if (window.innerHeight > scrolltofixed.clientHeight + header.clientHeight) {
                // console.log("set sticky");
                scrolltofixed.classList.add("sticky");
            } else {
                // console.log("unsset sticky");
                scrolltofixed.classList.remove("sticky");
            }
        }

        scrolltofixedSetter();
        window.addEventListener('resize', scrolltofixedSetter);
    }
});