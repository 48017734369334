import List from 'list.js';

export default class HvDataTable {
    constructor() {
        var self = this;
        if (!document.getElementById("mainlist")) return;

        const krankenkasse = document.body.getAttribute('data-krankenkasse');
        const wechsel = document.body.getAttribute('data-wechsel');
        self.listContainerElement = document.getElementById("list-container");

        let options = {
            valueNames: ['name', 'adresse', 'plz', 'ort', 'distance', 'zsrnummer', 'telefon', 'email', 'url', 'aufnahmestopp', ''],
            item: function (item) {
                let html = `<li>`;
                html = html + `<div class="row">`;
                html = html + `    <div class="col-12">`;
                html = html + `        <div class="fw-bold mb-2">${item.name}</div>`;
                html = html + `    </div>`;
                html = html + `    <div class="col-lg-6 col-xl-3">`;
                if (item.adresszusatz) {
                    html = html + `        <div>${item.adresszusatz}</div>`;
                }
                if (item.adresse) {
                    html = html + `        <div>${item.adresse}</div>`;
                }
                html = html + `        <div>${item.plz} ${item.ort}</div>`;
                if (item.distance > 0) {
                    let distance = parseFloat(item.distance).toFixed(1);
                    html = html + `        <div>` + Translator.trans("Distanz") + `: ${distance}km</div>`;
                }
                if (item.aufnahmestopp > 0) {
                    html = html + `        <div class="d-flex align-items-center text-danger mt-2 aufnahme-stopp">`;
                    html = html + `          <i class="bi-clipboard-plus"></i> <div class="">` + Translator.trans("Nimmt <u>keine</u> neuen Patienten&nbsp;auf") + `</div>`;
                    html = html + `        </div>`;
                } else {
                    html = html + `        <div class="d-flex align-items-center text-secondary mt-2 aufnahme-stopp">`;
                    html = html + `          <i class="bi-clipboard-plus"></i> <div class="">` + Translator.trans("Nimmt neue Patienten&nbsp;auf") + `</div>`;
                    html = html + `        </div>`;
                }
                html = html + `    </div>`;
                html = html + `    <div class="col-lg-6 col-xl-3">`
                if (item.fachgebiet.length > 0) {
                    html = html + `        <div>` + Translator.trans(item.fachgebiet) + `</div>`;
                }
                if (item.hmo.length > 0) {
                    html = html + `        <div>${item.hmo}</div>`;
                }
                if (item.gemeinschaftspraxis > 0) {
                    html = html + `        <div>` + Translator.trans("Gemeinschaftspraxis") + `</div>`;
                }
                if (item.aerztenetz && item.aerztenetzurl) {
                    html = html + `        <div><a rel="nofollow" target="_blank" class="text-secondary"  href="${item.aerztenetzurl}">${item.aerztenetz}</a></div>`;
                } else if (item.aerztenetz) {
                    html = html + `        <div>${item.aerztenetz}</div>`;
                }
                html = html + `    </div>`;
                html = html + `    <div class="col-lg-6 col-xl-3 text-secondary border-left-secondary">`;
                if (item.telefon.length > 0) {
                    html = html + `<div class="bi-telephone"><a href="tel:${item.telefon}">${item.telefon}</a></div>`;
                }
                if (item.latitude > 0 && item.longitude > 0) {
                    html = html + `        <div class="d-print-none bi-geo-alt"><a onclick="listShowDetailMap(this)" data-latitude="${item.latitude}" data-longitude="${item.longitude}">` + Translator.trans("Auf der Karte anzeigen") + `</a></div>`;
                }
                if (item.email.length > 0) {
                    html = html + `        <div class="d-print-none bi-envelope"><a href="mailto:${item.email}">` + Translator.trans("E-Mail") + `</a></div>`;
                    html = html + `        <div class="d-print-block d-none bi-envelope"><a href="mailto:${item.email}">${item.email}</a></div>`;
                }
                if (item.url.length > 0) {
                    let url = item.url;
                    if (!url.startsWith("http")) url = "http://" + url;
                    html = html + `        <div class="d-print-none bi-compass"><a target="_blank" href="${url}">` + Translator.trans("Website") + `</a></div>`;
                    html = html + `        <div class="d-print-block d-none bi-compass"><a target="_blank" href="${url}">${url}</a></div>`;
                }
                html = html + `    </div>`;
                html = html + `    <div class="col-lg-6 col-xl-3 text-secondary">`;
                html = html + `        <div class="bi-clipboard-minus">` + Translator.trans("ZSR-Nummer") + `: ${item.zsrnummer}</div>`;
                html = html + `        <div class="d-print-none pdf bi-printer"><a href="/${krankenkasse}/pdf/${item.zsrnummer}.pdf" target="_blank">` + Translator.trans("PDF drucken") + `</a></div>`;
                if (wechsel) {
                    html = html + `        <a class="d-print-none wechsel bi-box-arrow-right" href="${wechsel}">` + Translator.trans("Wechsel beantragen") + `</a>`;
                }
                html = html + `    </div>`;
                html = html + `</div>`;
                html = html + `</li>`;

                return html;
            },
            page: 50,
            pagination: {
                innerWindow: 5,
                left: 1,
                right: 1,
                item: '<li class="page-item"><a class="page page-link" href="#"></a></li>'
            }
        };
        let list = new List('mainlist', options);
        self.list = list;

        self.list.on("filterComplete", function () {
            self.loading(false);
            // console.timeEnd('updateList');
        });

        let sortElements = document.querySelectorAll("[data-sort-list]");
        sortElements.forEach((element) => {
            element.addEventListener("click", () => {

                document.querySelectorAll("#sort [data-sort-list]").forEach((resetElement) => {
                    if (resetElement.getAttribute("data-sort-list") !== element.getAttribute("data-sort-list")) {
                        resetElement.classList.remove("bi-arrow-down");
                        resetElement.classList.remove("bi-arrow-up");
                        resetElement.classList.add("bi-arrow-down-up");
                    }
                });

                element.classList.remove("bi-arrow-down-up");

                if (element.classList.contains("bi-arrow-down")) {
                    list.sort(element.getAttribute("data-sort-list"), {order: "desc"});
                    element.classList.remove("bi-arrow-down");
                    element.classList.add("bi-arrow-up");
                } else {
                    list.sort(element.getAttribute("data-sort-list"), {order: "asc"});
                    element.classList.remove("bi-arrow-up");
                    element.classList.add("bi-arrow-down");
                }
            });
        });

        // document.getElementById("printlist").addEventListener("click", (event) => {
        //     console.log("printlist click");
        // event.stopPropagation();
        // event.preventDefault();
        // self.list.page = 5000;
        // self.list.update();
        //     // window.print();
        //     // self.list.page = 10;
        //     // self.list.update();
        // });
    }

    init(dataservice) {
        // console.log("datatable.init");
        let self = this;
        if (self.initialized === true) return false;

        self.dataservice = dataservice;
        self.list.add(self.dataservice.jsonData);
        self.loading(false);

        self.initialized = true;
        return true;
    }

    updateView(ignoreInbounds = false) {
        // console.log("datatable.updateView");
        var self = this;
        self.loading(true);
        setTimeout(() => {
            // console.time('updateList');
            let displayCount = 0;
            // let ids = [];
            self.list.filter(function (item) {
                let objects = self.dataservice.jsonData.filter(obj => {
                    return obj.id === item._values.id;
                });
                if (objects.length === 0) return false;
                let markerItem = objects[0];

                if (markerItem["display"] && (ignoreInbounds || markerItem["inbounds"])) {
                    displayCount++;
                    // ids.push(markerItem["zsrnummer"]);
                }
                item._values.distance = markerItem["distance"];
                return markerItem["display"] && (ignoreInbounds || markerItem["inbounds"]);
            });
            // console.log('displayCount: ' + displayCount);
            // document.getElementById("printids").value = ids.join(",");
            if (displayCount === 0) {
                document.getElementById("emptylist").classList.remove("d-none");
            } else {
                document.getElementById("emptylist").classList.add("d-none");
            }
        }, 0);
    }

    loading(enabled) {
        let self = this;
        if (enabled) {
            self.listContainerElement.classList.add("loading");
        } else {
            self.listContainerElement.classList.remove("loading");
        }
    }

}
