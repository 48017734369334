import HvDataTable from './HvDataTable';
import HvMap from './HvMap';
import HvDataService from './HvDataService';
import loadGoogleMapsApi from "load-google-maps-api";

let dataservice;
let hvmap = new HvMap();
let datatable = new HvDataTable();

String.prototype.replaceChars = function () {
    return this.replace(/[a-zA-Z]/g, function (c) {
        return String.fromCharCode((c <= "Z" ? 90 : 122) >= (c = c.charCodeAt(0) + 13) ? c : c - 26);
    });
};

// show detail
function listShowDetailMap(element) {
    hvmap.showDetailMap(element);
}

window.listShowDetailMap = listShowDetailMap;

// select filter
function selectFilter(element) {
    window.setSelected(element.getAttribute("data-filter"), element.getAttribute("data-value"));
    // document.getElementById("filter").dispatchEvent(new SubmitEvent("submit"));
    dataservice.prepareFilter(() => {
        let activeToggle = document.querySelector("[data-toggle-center].active");
        if (activeToggle.getAttribute("data-toggle-center") === "map-container") {
            hvmap.updateView();
        } else {
            datatable.updateView(true);
        }
    });
}

window.selectFilter = selectFilter;

document.addEventListener("DOMContentLoaded", () => {
    let mapElement = document.getElementById("map");
    if (!mapElement) return;

    loadGoogleMapsApi({
        key: mapElement.getAttribute("data-apikey")
    }).then(() => {
        let url = "/aerzte/" + document.getElementsByTagName("body")[0].getAttribute("data-krankenkasse");
        if (!document.getElementById("map-container").classList.contains("d-none")) {
            hvmap.init();
        }

        fetch(url).then(response => response.text()).then(data => {
            data = atob(data.replaceChars());
            data = JSON.parse(data);
            let enableKantonLayer = window.getComputedStyle(document.body).getPropertyValue('--map-enable-kanton').trim() === "true";
            data.forEach((item) => {
                item["display"] = !enableKantonLayer;
            });
            dataservice = new HvDataService(data);
            if (!document.getElementById("map-container").classList.contains("d-none")) {
                hvmap.setupDataservice(dataservice);
            } else if (!document.getElementById("list-container").classList.contains("d-none")) {
                datatable.init(dataservice);
                datatable.updateView(true);
            }
        });
    });

    let submitTimeout = null;
    document.getElementById("filter").addEventListener("submit", (event) => {
        event.preventDefault();
        clearTimeout(submitTimeout);

        dataservice.prepareFilter(() => {
            let activeToggle = document.querySelector("[data-toggle-center].active");
            if (activeToggle.getAttribute("data-toggle-center") === "map-container") {
                hvmap.updateView();
            } else {
                datatable.updateView(true);
            }
        });
        return false;
    });

    let listmapElements = document.querySelectorAll("#listmap>div");
    document.querySelectorAll("[data-toggle-center]").forEach((toggleElement) => {
        toggleElement.addEventListener("click", () => {

            document.querySelectorAll("[data-toggle-center]").forEach((inactivateElement) => {
                inactivateElement.classList.remove("active");
            });

            listmapElements.forEach((listmapElement) => {
                if (listmapElement.id === toggleElement.getAttribute("data-toggle-center")) {
                    listmapElement.classList.remove("d-none");
                    toggleElement.classList.add("active");
                } else {
                    listmapElement.classList.add("d-none");
                }
            });

            if (toggleElement.getAttribute("data-toggle-center") === "list-container") {
                datatable.init(dataservice);
                datatable.updateView();
            } else if (toggleElement.getAttribute("data-toggle-center") === "map-container") {
                if (!hvmap.init()) {
                    hvmap.setupDataservice(dataservice)
                    hvmap.updateView();
                } else {
                    hvmap.setupDataservice(dataservice)
                    hvmap.updateView();
                }
            }
        });
    });
});