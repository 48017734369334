import {Modal} from "bootstrap";

export default class BootstrapModal {
    constructor(options) {
        let html =
            '<div class="modal fade">' +
            '  <div class="modal-dialog modal-dialog-centered ' + options.modalDialogClass + '">' +
            '    <div class="modal-content">' +
            '      <div class="modal-header">' +
            '        <div class="modal-title fw-bold">' + options.title + '</div>' +
            '        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>' +
            '      </div>' +
            '      <div class="modal-body">' + options.message + '</div>' +
            '    </div>' +
            '  </div>' +
            '</div>';

        var template = document.createElement('template');

        template.innerHTML = html;
        let modalElement = template.content.firstChild;
        // document.body.appendChild(modalElement);
        this.modal = Modal.getOrCreateInstance(modalElement, {
            keyboard: true,
            backdrop: true
        });

        return this;
    }

    getElement() {
        return this.modal._element;
    }

    show() {
        this.modal.show();
    }

    hide() {
        this.modal.hide();
    }

}
