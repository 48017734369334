import {MarkerClusterer, SuperClusterAlgorithm} from '@googlemaps/markerclusterer';

var styles = require('./mapstyle.json');
import * as kantoncenter from './kantoncenter.json';
import BootstrapModal from './BootstrapModal';
import CustomRenderer from './CustomRenderer';
import {map} from 'core-js/internals/array-iteration';

export default class HvMap {
    constructor() {
        this.bodyStyles = window.getComputedStyle(document.body);
    }

    init() {
        let self = this;
        if (self.initialized === true) return false;
        self.initialized = true;
        const colorPrimary = self.bodyStyles.getPropertyValue('--bs-primary').trim();
        const colorSecondary = self.bodyStyles.getPropertyValue('--bs-secondary').trim();
        const colorKantonLayer = self.bodyStyles.getPropertyValue('--map-kanton-fill-color').trim();
        const krankenkasse = document.body.getAttribute('data-krankenkasse').trim();
        const enableKantonLayer = self.bodyStyles.getPropertyValue('--map-enable-kanton').trim() === 'true';
        const countryOpacity = self.bodyStyles.getPropertyValue('--map-country-opacity').trim();
        const disabledKantonColor = self.bodyStyles.getPropertyValue('--map-kanton-disabled-color').trim();
        const disabledKantonOpacity = self.bodyStyles.getPropertyValue('--map-kanton-disabled-opacity').trim();
        self.enableKantonLayer = enableKantonLayer;
        self.mapElement = document.getElementById('map');
        self.mapContainerElement = document.getElementById('map-container');
        self.loading(true);

        let markerFill = colorPrimary.replace('#', '%23');
        self.markerIcon = {
            url: 'data:image/svg+xml;utf-8, <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 26 34" height="34" width="26"><path style="fill:' + markerFill + '" d="m 13.000002,34.000001 c 0,0 12.854209,-12.084381 12.854209,-21.252869 a 12.854213,12.751723 0 0 0 -25.7084225,0 c 0,9.168488 12.8542135,21.252869 12.8542135,21.252869 z m 0,-14.877006 a 6.4271066,6.3758615 0 1 1 0,-12.7517225 6.4271068,6.3758614 0 0 1 0,12.7517225 z"/></svg>',
            size: new google.maps.Size(26, 34),
            scaledSize: new google.maps.Size(26, 34)
        };

        self.defaultCenter = new google.maps.LatLng(46.818188, 8.227512);
        self.defaultZoom = 8;
        self.lastAddress = '';

        // console.log(styles);
        // return;

        let mapProp = {
            center: self.defaultCenter,
            zoom: self.defaultZoom,
            maxZoom: 17,
            mapTypeId: google.maps.MapTypeId.ROADMAP,
            styles: styles,
            restriction: {
                latLngBounds: {
                    east: 10.49234 + 4,
                    north: 47.808455 + 4,
                    south: 45.81792 - 4,
                    west: 5.95608 - 4
                },
                strictBounds: false,
            },
        };

        // console.time('init map');

        self.map = new google.maps.Map(self.mapElement, mapProp);
        let map = self.map;

        let dataLayoutKantone = new google.maps.Data({map: map});
        self.dataLayoutKantone = dataLayoutKantone;
        dataLayoutKantone.setStyle({
            fillColor: colorKantonLayer,
            fillOpacity: enableKantonLayer ? 1 : 0,
            strokeWeight: 0.5,
            strokeColor: colorPrimary,
        });
        self.kantonLabels = {};
        dataLayoutKantone.loadGeoJson('/geodata/' + krankenkasse + '.json', null, function () {
            if (enableKantonLayer) {
                //check if kanton is enabled!
                document.querySelectorAll('footer ul.kantone a[data-value]').forEach((item) => {
                    var code = item.getAttribute('data-value').toUpperCase();
                    self.kantonLabels[code] = new google.maps.Marker({
                        position: kantoncenter[code],
                        map: map,
                        icon: '/img/empty.png',
                        label: {
                            color: colorSecondary,
                            fontWeight: 'bold',
                            className: 'kanton-label',
                            text: code,
                            fontSize: self.getLabelFontSize()
                        },
                    });
                });
            }
        });

        dataLayoutKantone.addListener('click', (e) => {
            dataLayer.push({
                'event': 'mapClick',
                'click_lat': e.latLng.lat(),
                'click_long': e.latLng.lng()
            });
        });

        if (enableKantonLayer) {
            dataLayoutKantone.addListener('mouseover', function (event) {
                dataLayoutKantone.revertStyle();
                dataLayoutKantone.overrideStyle(event.feature, {fillColor: '#BAB5AB'});
            });
            dataLayoutKantone.addListener('click', function (event) {
                // console.log('kantonlayerklick');
                window.setSelected('kanton', event.feature.getProperty('kanton'));
                self.prepareFilterAndUpdateView();
            });

            dataLayoutKantone.addListener('mouseout', function () {
                dataLayoutKantone.revertStyle();
            });
        }

        let dataLayoutKantoneDisabled = new google.maps.Data({map: map});
        dataLayoutKantoneDisabled.setStyle({
            fillColor: disabledKantonColor,
            fillOpacity: disabledKantonOpacity,
            strokeWeight: 0,
            strokeColor: '#FF0000',
        });
        dataLayoutKantoneDisabled.loadGeoJson('/geodata/' + krankenkasse + '-disable.json');

        let dataLayoutCountry = new google.maps.Data({map: map});
        dataLayoutCountry.setStyle({
            fillColor: '#E5E3DF',
            fillOpacity: countryOpacity,
            strokeWeight: 2,
            strokeColor: colorPrimary,
        });
        dataLayoutCountry.loadGeoJson('/geodata/ch.json');

        if (self.enableKantonLayer) {
            self.dataLayoutKantone.setStyle({
                fillColor: '#E5E3DF',
                fillOpacity: self.enableKantonLayer ? 1 : 0,
                strokeWeight: 0.5,
                strokeColor: colorPrimary,
            });
        }
        // console.timeEnd('init map');

        self.filter = document.getElementById('filter');
        let filter = self.filter;

        // autosubmit:
        // filter.querySelectorAll("input,select").forEach((input) => {
        //     input.addEventListener("change", () => {
        //         filter.dispatchEvent(new SubmitEvent("submit"));
        //         if (input.tagName === "SELECT") {
        //             if (input.value.length > 0) {
        //                 input.classList.add("selected");
        //             } else {
        //                 input.classList.remove("selected");
        //             }
        //         }
        //     });
        //     input.addEventListener("keyup", () => {
        //         clearTimeout(submitTimeout);
        //
        //         submitTimeout = setTimeout(() => {
        //             filter.dispatchEvent(new SubmitEvent("submit"));
        //         }, 500);
        //     });
        // });

        let geolocation = document.getElementById('geolocate');
        if (navigator.geolocation) {
            geolocation.addEventListener('click', function (event) {
                event.preventDefault();
                navigator.geolocation.getCurrentPosition(
                    function (pos) {
                        //success
                        let crd = pos.coords;

                        dataLayer.push({
                            'event': 'mapGeolocation',
                            'click_lat': crd.latitude,
                            'click_long': crd.longitude
                        });

                        let latlng = new google.maps.LatLng(crd.latitude, crd.longitude);
                        let geocoder = new google.maps.Geocoder();
                        geocoder.geocode({'location': latlng}, function (results, status) {
                            if (status === 'OK' && results.length > 0) {
                                // console.log(results);

                                let strasse = '';
                                let strasseNr = '';
                                let plz = '';
                                let ort = '';
                                results[0].address_components.forEach((component) => {
                                    if (component.types.includes('street_number')) {
                                        strasseNr = component.long_name;
                                    } else if (component.types.includes('route')) {
                                        strasse = component.long_name;
                                    } else if (component.types.includes('locality')) {
                                        ort = component.long_name;
                                    } else if (component.types.includes('postal_code')) {
                                        plz = component.long_name;
                                    }
                                });
                                filter.querySelector('input[id=adresse]').value = (strasse + ' ' + strasseNr).trim();
                                filter.querySelector('input[id=ort]').value = ort;
                                filter.querySelector('input[id=plz]').value = plz;

                                let distances = filter.querySelectorAll('input[name=distance]:checked');
                                if (distances.length === 0) {
                                    distances.forEach((element) => {
                                        element.checked = false
                                    });
                                    filter.querySelector('input[id=distance-5]').checked = true;
                                }

                                self.prepareFilterAndUpdateView();
                            }
                        });

                    }, function (err) {
                        if (err.code === err.PERMISSION_DENIED) {
                            geolocation.remove();
                            // new BootstrapModal({
                            //     title: Translator.trans("Fehler"),
                            //     message: Translator.trans("Sie haben in den Einstellungen die Ortung deaktiviert. Bitte erlauben Sie die Ortung oder geben Sie die Adresse im Suchfeld ein.")
                            // }).show();
                        } else {
                            new BootstrapModal({
                                title: Translator.trans('Fehler'),
                                message: Translator.trans('Die Ortung Ihrer Position war leider nicht möglich. Bitte geben Sie die Adresse im Suchfeld ein.')
                            }).show();
                        }
                    }, {
                        enableHighAccuracy: true,
                        timeout: 5000,
                        maximumAge: 0
                    }
                );
            });
        } else {
            geolocation.remove();
        }

        google.maps.event.addListenerOnce(map, 'tilesloaded', () => {
            self.map.addListener('zoom_changed', () => {
                // console.log('current zoom level', self.map.getZoom());
                self.updateMarkerVisibilityByBounds();
                self.setLabelFontSize();
            });
            self.map.addListener('center_changed', () => {
                // console.log("center_changed event");
                self.updateMarkerVisibilityByBounds();
            });
        });

        return true;
    }

    prepareFilterAndUpdateView() {
        // console.log('map.prepareFilterAndUpdateView');
        let self = this;
        self.loading(true);
        self.dataservice.prepareFilter(() => {
            self.updateView();
        }, false);
    }

    updateView() {
        // console.log('map.updateView');
        let self = this;
        self.loading(true);
        var filterSet = self.dataservice.isFilterSet();

        if (self.enableKantonLayer) {
            const colorPrimary = self.bodyStyles.getPropertyValue('--bs-primary').trim();
            self.dataLayoutKantone.setStyle({
                fillColor: '#E5E3DF',
                fillOpacity: filterSet ? 0 : 1,
                strokeWeight: 0.5,
                strokeColor: colorPrimary,
            });
            for (let kantonLabelMarker of Object.values(self.kantonLabels)) {
                kantonLabelMarker.setMap(self.dataservice.isFilterSet() ? null : self.map);
            }
        }

        let bounds = new google.maps.LatLngBounds();
        self.dataservice.jsonData.forEach((item) => {
            let marker = self.markerObjects[item['id']];
            if (marker) {
                if (item['display']) {
                    marker.setVisible(true);
                    bounds.extend(marker.getPosition());
                } else {
                    marker.setVisible(false);
                }
            }
        });

        if (bounds.isEmpty()) {
            if (!self.enableKantonLayer) {
                new BootstrapModal({
                    title: Translator.trans('Fehler'),
                    message: Translator.trans('Die Suche hat kein Resultat ergeben.')
                }).show();
            }

            self.map.setCenter(self.defaultCenter);
            self.map.setZoom(self.defaultZoom);
        } else {
            self.map.fitBounds(bounds);
        }
        self.updateMarkerVisibilityByBounds();

        self.loading(false);
    }

    setupDataservice(dataservice) {
        let self = this;
        if (self.dataservice) return false;

        self.dataservice = dataservice;
        let map = self.map;

        // console.time('init markers');
        let bounds = new google.maps.LatLngBounds();
        let markers = [];

        self.markerByPosition = {};
        self.markerObjects = {};
        self.dataservice.jsonData.forEach((item) => {
            let id = item.id;
            if (item.latitude === 0 || item.latitude === -1 || item.latitude === null) return;

            item['inbounds'] = true;
            let latLng = new google.maps.LatLng(item.latitude, item.longitude);
            let positionKey = item.latitude + '_' + item.longitude;
            if (!(positionKey in self.markerByPosition)) {
                self.markerByPosition[positionKey] = [];
            }

            self.markerByPosition[positionKey].push(item);

            let marker = new google.maps.Marker({
                id: id,
                position: latLng,
                map: map,
                icon: self.markerIcon,
            });
            self.markerObjects[id] = marker;
            bounds.extend(latLng);

            if (self.enableKantonLayer) {
                marker.setMap(null);
            } else {
                markers.push(marker);
            }

            google.maps.event.addListener(marker, 'click', () => {
                let html = '';
                self.markerByPosition[positionKey].forEach((item, index) => {
                    if (item['display']) {
                        html = html + self.itemToModalContent(item, index === 0);
                    }
                });

                let modal = new BootstrapModal({
                    title: '',
                    message: html,
                    modalDialogClass: 'modal-lg modal-fullscreen-md-down'
                });

                modal.getElement().addEventListener('hidden.bs.modal', function () {
                    modal.modal.dispose();
                })

                modal.getElement().addEventListener('shown.bs.modal', function () {
                    // console.log('shown, now init map');
                    let mapElement = modal.getElement().querySelector('.map');
                    if (!mapElement.classList.contains('initialized')) {
                        let latLng = new google.maps.LatLng(item.latitude, item.longitude);
                        let mapProp = {
                            center: latLng,
                            zoom: 16,
                            mapTypeId: google.maps.MapTypeId.ROADMAP,
                            styles: styles,
                        };
                        let map = new google.maps.Map(mapElement, mapProp);

                        new google.maps.Marker({
                            id: 'detail',
                            position: latLng,
                            map: map,
                            icon: self.markerIcon,
                        });
                        mapElement.classList.add('initialized')
                    }

                });
                modal.show();

                dataLayer.push({
                    'event': 'markerClick',
                    'click_lat': marker.getPosition().lat(),
                    'click_long': marker.getPosition().lng()
                });
            });
        });

        // console.timeEnd('init markers');
        // console.time('clusterer');
        let radius = parseInt(self.bodyStyles.getPropertyValue('--map-radius').trim());
        // if (radius === 0 || isNaN(radius)) radius = 180;
        // console.log('radius: ' + radius);
        let algorithm = new SuperClusterAlgorithm({radius: radius});
        if (self.bodyStyles.getPropertyValue('--map-center-fit-bounds').trim() === 'true') {
            map.fitBounds(bounds);
        }
        let renderer = new CustomRenderer();
        self.cluster = new MarkerClusterer({
            map: map, markers: markers, algorithm: algorithm, renderer: renderer, onClusterClick: function (event, cluster, map) {
                let currentZoom = map.getZoom();
                map.fitBounds(cluster.bounds);
                if (map.getZoom() === currentZoom) {
                    map.setZoom(currentZoom + 1);
                }
            }
        });
        google.maps.event.addListener(self.cluster, 'clusteringend', () => {
            self.loading(false);
        });

        // console.timeEnd('clusterer');
        return true;
    }

    fitBounds() {
        var self = this;
        // console.log('idle!');
        let bounds = new google.maps.LatLngBounds();
        self.dataservice.jsonData.forEach((item) => {
            let marker = self.markerObjects[item['id']];
            if (marker) {
                if (item['display']) {
                    bounds.extend(marker.getPosition());
                }
            }
        });
        self.map.fitBounds(bounds);
    }

    updateMarkerVisibilityByBounds() {
        let self = this;
        if (self.cluster && self.map && self.map.getBounds()) {
            let markers = [];
            // console.log('updateMarkerVisibilityByBounds');
            self.dataservice.jsonData.forEach((item) => {
                let marker = self.markerObjects[item['id']];
                if (marker) {
                    //if map ist active check for inbounds visibility
                    if (document.querySelectorAll('[data-toggle-center="map-container"].active').length === 1) {
                        item['inbounds'] = self.map.getBounds().contains(marker.getPosition());
                    } else {
                        item['inbounds'] = true;
                    }

                    if (item['display'] && item['inbounds']) {
                        marker.setVisible(true);
                        markers.push(marker);
                    } else {
                        marker.setVisible(false);
                    }
                }
            });
            self.cluster.clearMarkers();
            self.cluster.addMarkers(markers);
        }
    }

    loading(enabled) {
        let self = this;
        if (enabled) {
            self.mapContainerElement.classList.add('loading');
        } else {
            self.mapContainerElement.classList.remove('loading');
        }
    }

    showDetailMap(element) {
        let self = this;

        let row = element.closest('.row');
        if (row.querySelector('.detail-map')) {
            row.querySelector('.detail-map').remove();
            return;
        }
        let mapElement = document.createElement('div');
        mapElement.classList.add('detail-map');
        row.appendChild(mapElement);

        let latLng = new google.maps.LatLng(element.getAttribute('data-latitude'), element.getAttribute('data-longitude'));

        let mapProp = {
            center: latLng,
            zoom: 15,
            mapTypeId: google.maps.MapTypeId.ROADMAP,
            styles: styles,
        };
        let map = new google.maps.Map(mapElement, mapProp);

        new google.maps.Marker({
            id: 'detail',
            position: latLng,
            map: map,
            icon: self.markerIcon,
        });
    }

    getLabelFontSize() {
        let self = this;

        let fontSize = '18px';
        if (self.map.getZoom() < 8) {
            fontSize = '12px';
        }
        if (self.map.getZoom() < 7) {
            fontSize = '10px';
        }
        if (self.map.getZoom() < 6) {
            fontSize = '8px';
        }
        return fontSize;
    }

    setLabelFontSize() {
        let self = this;
        if (!self.enableKantonLayer) return;

        let fontSize = self.getLabelFontSize();

        for (let kantonLabelMarker of Object.values(self.kantonLabels)) {
            let label = kantonLabelMarker.getLabel();
            if (label.fontSize !== fontSize) {
                // console.log('set font size to ' + fontSize);
                label.fontSize = fontSize;
                kantonLabelMarker.setLabel(label);
            }
        }
    }

    itemToModalContent(item, isFirst = true) {
        const krankenkasse = document.body.getAttribute('data-krankenkasse');
        const wechsel = document.body.getAttribute('data-wechsel');

        let html = `<div>`;

        if (!isFirst) {
            html = html + `<hr class="text-primary"/>`;
        }
        html = html + `<div class="row">`;
        html = html + `    <div class="col-12">`;
        html = html + `        <div class="fw-bold mb-2">${item.name}</div>`;
        html = html + `    </div>`;
        html = html + `    <div class="col-sm-8">`;

        {
            html = html + `<div class="row">`;
            html = html + `    <div class="col-sm-6">`;
            if (item.adresszusatz) {
                html = html + `        <div>${item.adresszusatz}</div>`;
            }
            if (item.adresse) {
                html = html + `        <div>${item.adresse}</div>`;
            }
            html = html + `        <div>${item.plz} ${item.ort}</div>`;
            // html = html + `        <div>Kanton ${item.kanton}</div>`;
            if (item.distance > 0) {
                let distance = parseFloat(item.distance).toFixed(1);
                html = html + `        <div>` + Translator.trans('Distanz') + `: ${distance}km</div>`;
            }
            if (item.aufnahmestopp > 0) {
                html = html + `        <div class="d-flex align-items-center text-danger mt-2 aufnahme-stopp">`;
                html = html + `          <i class="bi-clipboard-plus"></i> <div class="">` + Translator.trans('Nimmt <u>keine</u> neuen Patienten&nbsp;auf') + `</div>`;
                html = html + `        </div>`;
            } else {
                html = html + `        <div class="d-flex align-items-center text-secondary mt-2 aufnahme-stopp">`;
                html = html + `          <i class="bi-clipboard-plus"></i> <div class="">` + Translator.trans('Nimmt neue Patienten&nbsp;auf') + `</div>`;
                html = html + `        </div>`;
            }
            html = html + `    </div>`;
            html = html + `    <div class="col-sm-6">`
            if (item.fachgebiet.length > 0) {
                html = html + `        <div>` + Translator.trans(item.fachgebiet) + `</div>`;
            }
            if (item.hmo.length > 0) {
                html = html + `        <div>${item.hmo}</div>`;
            }
            if (item.gemeinschaftspraxis > 0) {
                html = html + `        <div>` + Translator.trans('Gemeinschaftspraxis') + `</div>`;
            }
            if (item.aerztenetz && item.aerztenetzurl) {
                html = html + `        <div><a rel="nofollow" target="_blank" href="${item.aerztenetzurl}">${item.aerztenetz}</a></div>`;
            } else if (item.aerztenetz) {
                html = html + `        <div>${item.aerztenetz}</div>`;
            }
            html = html + `    </div>`;
            html = html + `    <div class="col-12">`
            html = html + `    <hr class="text-secondary my-4"/>`
            html = html + `    </div>`;
            html = html + `    <div class="col-sm-6 text-secondary">`;
            if (item.telefon.length > 0) {
                html = html + `<div class="bi-telephone"><a href="tel:${item.telefon}">${item.telefon}</a></div>`;
            }
            if (item.email.length > 0) {
                html = html + `        <div class="bi-envelope"><a href="mailto:${item.email}">` + Translator.trans('E-Mail') + `</a></div>`;
            }
            if (item.url.length > 0) {
                let url = item.url;
                if (!url.startsWith('http')) url = 'http://' + url;
                html = html + `        <div class="bi-compass"><a target="_blank" href="${url}">` + Translator.trans('Website') + `</a></div>`;
            }
            html = html + `    </div>`;
            html = html + `    <div class="col-sm-6 text-secondary">`;
            html = html + `        <div class="bi-clipboard-minus">` + Translator.trans('ZSR-Nummer') + `: ${item.zsrnummer}</div>`;
            html = html + `        <div class="pdf bi-printer"><a href="/${krankenkasse}/pdf/${item.zsrnummer}.pdf" target="_blank">` + Translator.trans('PDF drucken') + `</a></div>`;
            if (wechsel) {
                html = html + `        <a class="wechsel bi-box-arrow-right" href="${wechsel}">` + Translator.trans('Wechsel beantragen') + `</a>`;
            }
            html = html + `    </div>`;
            html = html + `</div>`;
        }

        html = html + `        </div>`;
        html = html + `    <div class="col-sm-4">`;
        if (isFirst) {
            html = html + `        <div class="map"></div>`;
        }
        html = html + `    </div>`;
        html = html + `  </div>`;

        return html + `</div>`;
    }

}
