import List from "list.js";

let adminlist = document.getElementById("adminlist");
if (adminlist) {
    var xhttp = new XMLHttpRequest();
    xhttp.onreadystatechange = function () {
        if (this.readyState === XMLHttpRequest.DONE && this.status === 200) {
            let data = this.responseText;
            data = atob(data.replaceChars());
            data = JSON.parse(data);

            new List('adminlist', {
                valueNames: ['zsrnummer', 'name', 'adresse', 'ort', 'kanton', 'latitude', 'longitude'],
                // item: "<tr><td class='name'></td><td class='adresse'></td></tr>",
                item: function (item) {
                    var html = "<tr>";
                    html = html + `<td>${item.name}</td>`;
                    html = html + `<td>${item.adresse}</td>`;
                    html = html + `<td>${item.ort}</td>`;
                    html = html + `<td>${item.kanton}</td>`;
                    html = html + `<td>`;
                    if (item.latitude) {
                        html = html + `  <a href="http://www.google.com/maps/place/${item.latitude},${item.longitude}" target="_blank">${item.latitude}, ${item.longitude}</a>`;
                        html = html + `  <a onclick="window.deleteLocation(this, '${item.zsrnummer}')" class="small">Standort löschen</a>`;
                    } else {
                        html = html + `Keine Koordinaten`;
                    }
                    html = html + `</td>`;
                    html = html + "</tr>";

                    return html;
                },
                page: 20,
                pagination: {
                    innerWindow: 5,
                    left: 1,
                    right: 1,
                    item: '<li class="page-item"><a class="page page-link" href="#"></a></li>'
                }
            }, data);
        }
    };

    xhttp.open("GET", "/aerzte/" + document.getElementsByTagName("body")[0].getAttribute("data-krankenkasse"), true);
    xhttp.send();
}

window.deleteLocation = function (element, zsrnr) {
    fetch('/admin/arzt/koordinaten/delete/' + zsrnr)
        .then((response) => response.text())
        .then((text) => {
            element.parentNode.textContent = text;
        });
};